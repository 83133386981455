/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

.calendar .cell {
    padding: 30px;
    background-color: black;
    border: 1px solid #fff;
}

.calendar .cell {
    padding: 5px;
    min-height: 100px;
    background-color: #fff;
    border: 1px solid #000;
    justify-content: start;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.calendar .cell.active {
    background-color: var(--success) !important;
    font-weight: bolder;
}

.calendar .cell.disabled, .calendar .cell.holiday, .calendar .cell.unavailable {
    cursor: auto;
    justify-content: space-between
}
.calendar .cell.today {
    background-color: #d2f8d2;
}
.calendar .cell.has-booked {
    background-color: skyblue;
}
.calendar .cell.disabled {
    background-color: rgba(0, 0, 0, 0.1);
}

.calendar .cell.holiday, .calendar .cell.unavailable {
    background-color: rgba(0, 0, 0, 0.1);
}

.calendar .cell:not(.disabled):not(.holiday):not(.unavailable):hover {
    background-color: var(--success) !important;
}

.calendar .cell-header {
    background-color: rgba(0, 0, 0, 1) !important;
    color: #fff;
    text-align: center;
    font-weight: bolder;
    padding: 5px 10px;
}

.calendar .date {
    font-weight: bolder;
    margin-bottom: 16px
}

.calendar .label .remark {
    font-size: 12px;
}
.content.content-fixed {
    background: rgba(0,0,0,0.03) !important
}

.datepicker-only-month-select .ngb-dp-content {
    display: none;
}

.text-line-through {
    text-decoration: line-through;
}